import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VBreadcrumbs,{attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c(VIcon,[_vm._v("mdi-forward")])]},proxy:true}])}),_c('div',{staticClass:"button-wrapper"},[_c(VBtn,{attrs:{"color":"cyan lighten-1 mr-2 ml-2","dark":""},on:{"click":function($event){return _vm.changeitemType('all')}}},[_vm._v("All")]),_c(VBtn,{attrs:{"color":"yellow darken-2 mr-2 ml-2","dark":""},on:{"click":function($event){return _vm.changeitemType('executable')}}},[_vm._v("Executable")]),_c(VBtn,{attrs:{"color":"green mr-2 ml-2","dark":""},on:{"click":function($event){return _vm.changeitemType('finished')}}},[_vm._v("Finished ")]),_c(VBtn,{attrs:{"color":"red mr-2 ml-2","dark":""},on:{"click":function($event){return _vm.changeitemType('error')}}},[_vm._v("Error ")])],1),_c(VCardTitle,[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{staticClass:"elevation-1 text-center",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"search":_vm.search,"footer-props":{
      'items-per-page-options': _vm.itemsPerPage
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
    var item = ref.item;
return [(item.status == 'finished')?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"green lighten-1","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle ")])]}}],null,true)},[_c('span',[_vm._v("finished")])]):(item.status == 'error')?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"red darken-3","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-decagram ")])]}}],null,true)},[_c('span',[_vm._v("error")])]):_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"yellow darken-3","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle ")])]}}],null,true)},[_c('span',[_vm._v("executable")])])]}},{key:"item.operation",fn:function(ref){
    var item = ref.item;
return [(item.operation == 'upload')?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"cyan","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-database-arrow-down ")])]}}],null,true)},[_c('span',[_vm._v("upload")])]):_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-cloud-alert ")])]}}],null,true)},[_c('span',[_vm._v("redownload")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }