<template>
  <v-card>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-forward</v-icon>
      </template>
    </v-breadcrumbs>
    <div class="button-wrapper">
      <v-btn
        color="cyan lighten-1 mr-2 ml-2"
        dark
        @click="changeitemType('all')"
        >All</v-btn
      >
      <v-btn
        color="yellow darken-2 mr-2 ml-2"
        dark
        @click="changeitemType('executable')"
        >Executable</v-btn
      >
      <v-btn color="green mr-2 ml-2" dark @click="changeitemType('finished')"
        >Finished
      </v-btn>
      <v-btn color="red mr-2 ml-2" dark @click="changeitemType('error')"
        >Error
      </v-btn>
    </div>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :search="search"
      :footer-props="{
        'items-per-page-options': itemsPerPage
      }"
      class="elevation-1 text-center"
    >
    <template v-slot:item.status="{ item }">
     <v-tooltip v-if="item.status == 'finished'" left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="green lighten-1" v-bind="attrs" v-on="on" dark>
            mdi-check-circle
          </v-icon>
        </template>
        <span>finished</span>
      </v-tooltip>
      <v-tooltip v-else-if="item.status == 'error'" left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="red darken-3" v-bind="attrs" v-on="on" dark>
            mdi-alert-decagram
          </v-icon>
        </template>
        <span>error</span>
      </v-tooltip>
      <v-tooltip v-else left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="yellow darken-3" v-bind="attrs" v-on="on" dark>
            mdi-check-circle
          </v-icon>
        </template>
        <span>executable</span>
      </v-tooltip>
    </template>
     <template v-slot:item.operation="{ item }">
       <v-tooltip v-if="item.operation == 'upload'" left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="cyan" v-bind="attrs" v-on="on" dark>
             mdi-database-arrow-down
          </v-icon>
        </template>
        <span>upload</span>
      </v-tooltip>
      <v-tooltip v-else left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="red" v-bind="attrs" v-on="on" dark>
            mdi-cloud-alert
          </v-icon>
        </template>
        <span>redownload</span>
      </v-tooltip>
     </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { paginationMixin } from '@/mixins/PaginationMixin.js'
import IntegrationService from '@/services/IntegrationService.js'

export default {
  mixins: [paginationMixin],
  watch: {
    search: {
      handler() {
        if (this.search !== undefined) {
          this.getDataFromAPI().then(data => {
            // console.log(data.items)
            this.items = data.items
            this.total = data.total
          })
        }
      }
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Integration Id',
          align: 'center',
          value: 'integration_id'
        },
        {
          text: 'System',
          align: 'center',
          value: 'system_name'
        },
        { text: 'dates', value: 'dates' },
        { text: 'operation', value: 'operation' },
        { text: 'status', value: 'status' },
      ]
    }
  },
  created() {
    this.setBreadcrumbs([
      { text: 'Home', to: { path: '/' } },
      {
        text: ':operation'
      }
    ])
    this.replaceBreadcrumb({
      find: ':operation',
      replace: {
        text: 'Operation',
        to: {
          name: 'Operations'
        },
        disabled: true
      }
    })
  },
  methods: {
    changeitemType(type) {
      this.$router
        .push({
          name: 'Operations',
          query: { type: type }
        })
        .catch(() => {})
    },
    getDataFromAPI(type, offset) {
      // console.log(this.options)
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        let items = []
        type = type !== undefined ? type : this.itemType
        const query = this.$route.query
        let total = 0
        let sort_by = sortBy.length == 1 ? sortBy[0] : '_id'
        let sort = -1
        if (sortBy !== '_id') {
          sort = sortDesc.length && sortDesc[0] == 1 ? -1 : 1
        }
        offset = offset !== 0 ? itemsPerPage * (page - 1) : 0
        IntegrationService.getOperations(
          itemsPerPage,
          offset || 0,
          sort_by,
          sort,
          type,
          this.search
        )
          .then(response => {
            // console.log(response.data.results)
            items = response.data.results
            total = response.data.count
            resolve({ items, total })
          })
          .catch(error => {
            console.log(error)
            store.dispatch('setLoader', false)
          })
      })
    },
  },
}
</script>

<style scoped></style>
